import React, {Component} from 'react'
import injectSheet from 'react-jss'

const styles = {
  image: {
    display: 'flex',
    width: 'calc(33.33vw - 4.5em)',
    height: 'calc(33.33vw - 4.5em)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    margin: '1em',
    minWidth: '96px',
    minHeight: '96px',
    maxWidth: '350px',
    maxHeight: '350px',
    '@media (max-width: 767px)': {
      width: 'calc(50vw - 5.5em)',
      height: 'calc(50vw - 5.5em)'
    },
    '@media (max-width: 580px)': {
      width: 'calc(100vw - 7em)',
      height: 'calc(100vw - 7em)'
    }
  }
}

class Image extends Component {
  render() {
    const {classes, src} = this.props
    return(
      <div className={classes.image} style={{backgroundImage: 'url(' + src + ')'}}></div>
    )
  }
}

export default injectSheet(styles)(Image)
