import React from 'react'
import {navigateTo} from 'gatsby-link'

import Layout from '../components/layout'
import JumboBackground from '../components/jumbo-background'
import CardBox from '../components/card-box'
import Card from '../components/card'
import ImageBox from '../components/image-box'
import Image from '../components/image'
import Button from '../components/button'

import Abbotsford1 from '../images/Abbotsford/Abbotsford1.jpg'
import Abbotsford2 from '../images/Abbotsford/Abbotsford2.jpg'
import Abbotsford3 from '../images/Abbotsford/Abbotsford3.jpg'
import Abbotsford4 from '../images/Abbotsford/Abbotsford4_dark.jpg'
import Abbotsford5 from '../images/Abbotsford/Abbotsford5.jpg'
import Abbotsford6 from '../images/Abbotsford/Abbotsford6.jpg'
import Abbotsford7 from '../images/Abbotsford/Abbotsford7.jpg'
import Background from '../images/background.jpg';
import Bathroom from '../images/bathroom_dark.jpg';

const NelsonStreet = ({location}) => {
  return(
    <Layout path={location.pathname}>
      <JumboBackground
        background={Abbotsford3}
      />
      <CardBox title="Nelson Street, Abbotsford" noSpacer center>
        <Card>
          <h3 style={{color: 'black'}}>A former single level warehouse, this site was transformed into a brilliant 'aged' two storey office space.
          <br/><br/>
          This is building No. 1 in the Rock Art Series, watch out for Building No. 2 as it comes to life just around the corner in Russell St!</h3>
          <ImageBox>
            <Image src={Background}/>
            <Image src={Bathroom}/>
            <Image src={Abbotsford1}/>
            <Image src={Abbotsford2}/>
            <Image src={Abbotsford5}/>
            <Image src={Abbotsford7}/>
            <Image src={Abbotsford3}/>
            <Image src={Abbotsford4}/>
            <Image src={Abbotsford6}/>
          </ImageBox>
          <Button dark onClick={() => navigateTo('/projects')}>Back to projects</Button>
        </Card>
      </CardBox>
    </Layout>
  )
}

export default NelsonStreet
