import React, {Component} from 'react'
import injectSheet from 'react-jss'

const styles = {
  imagebox: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%'
  }
}

class ImageBox extends Component {
  render() {
    const {classes, children} = this.props
    return(
      <div className={classes.imagebox}>
        {children}
      </div>
    )
  }
}

export default injectSheet(styles)(ImageBox)
