import React, {Component} from 'react'
import injectSheet from 'react-jss'

const styles = {
  card: {
    width: 'calc(100vw - 2em)',
    backgroundColor: 'white',
    borderTop: '8px solid #F37B1D',
    minWidth: '160px',
    margin: '1em',
    padding: '1em',
    maxWidth: '1200px'
  }
}


class Card extends Component {
  render() {
    const {classes, children} = this.props
    return(
      <div className={classes.card}>
        {children}
      </div>
    )
  }
}

export default injectSheet(styles)(Card)
